/* * @Author: liuzhixiang * @Date: 2021-08-05 15:58:23 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-08-25 14:26:38 */
<template>
  <div
    class="checkRecordDetails"
    :style="{ marginTop: isWxApplets ? '46px' : '' }"
  >
    <template v-if="isWxApplets">
      <van-nav-bar
        title="签到详情"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div class="detailsBox" v-for="(item, index) in list" :key="index">
      <div class="detailsBoxTop">
        <div class="serialNo">
          {{ index + 1 > 10 ? index + 1 : "0" + (index + 1) }}
        </div>
        <div class="content">{{ item.Remark }}</div>
      </div>
      <div class="detailsBoxBottom">
        <div class="date">
          {{ item.StartTime | dataYMD }}
        </div>
        <div class="mode">
          考勤:
          {{
            item.SignStatus == 0
              ? "直播签到"
              : item.SignStatus == 1
              ? "手动签到"
              : item.SignStatus == 2
              ? "老师签到"
              : "未签到"
          }}
        </div>
      </div>
    </div>
    <!-- <el-table
      :data="list"
      border
      header-cell-class-name="hader_th"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="40"> </el-table-column>
      <el-table-column prop="Remark" label="内容"> </el-table-column>
      <el-table-column label="时间">
        <template slot-scope="scope">
          {{ scope.row.StartTime | dataYMD }} <br />{{
            scope.row.StartTime | hms
          }}~{{ scope.row.EndTime | hms }}
        </template>
      </el-table-column>
      <el-table-column label="打卡方式" width="60">
        <template slot-scope="scope">
          签到状态（0直播签到、1手动签到、2老师签到、null未签到）
          {{
            scope.row.SignStatus == 0
              ? "直播签到"
              : scope.row.SignStatus == 1
              ? "手动签到"
              : scope.row.SignStatus == 2
              ? "老师签到"
              : "未签到"
          }}
        </template>
      </el-table-column>
    </el-table> -->
  </div>
</template>

<script>
import { studentQueryStudentSignDetail } from "@/api/personal";
import noData from "@/components/noData";
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      isWxApplets: true,
    };
  },
  components: {
    noData,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "签到详情";
      this.isWxApplets = false;
    }
    this.route = this.$route.query;
  },
  mounted() {
    this.init();
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    async init() {
      let parm = {
        studentId: this.route.UserId,
        CourseId: this.route.CourseId,
        StartTime: this.route.StartTime,
        EndTime: this.route.EndTime,
      };
      const res = await studentQueryStudentSignDetail(parm);
      if (res.success === true) {
        this.list = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.detailsBox {
  width: 100%;
  border-bottom: 1px solid #afafaf;
  margin-bottom: 20px;
  .detailsBoxTop {
    display: flex;
    margin-bottom: 10px;
    .serialNo {
      width: 30px;
    }
    .content {
      flex: 1;
      margin-right: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .detailsBoxBottom {
    width: calc(100% - 30px);
    margin-left: 30px;
    margin-bottom: 7px;
    display: flex;
    justify-content: space-between;
    .date {
      color: #afafaf;
    }
  }
}
.checkRecordDetails {
  background: #fff;
  padding: 15px 18px;
  .el-table {
    font-size: 12px;
    /deep/th,
    /deep/td {
      text-align: center;
      .cell {
        padding: 0;
      }
    }
    /deep/td {
      .cell {
        color: #333333;
      }
      &:nth-child(2) {
        text-align: left;
        padding: 12px 5px;
      }
    }
  }
}
/deep/.hader_th {
  color: #ffffff;
  background-color: #3a78f9 !important;
  font-size: 13px;
  font-weight: normal;
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
